module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-K91MZB7279"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","hr"],"defaultLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":true,"fallbackLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"perina-kulic","short_name":"perina","description":"Professional translator, proofreader, content writer and writer. Top service and reasonable rates.","lang":"en","start_url":"/","background_color":"#0a0a0a","theme_color":"#0b0b0c","display":"minimal-ui","icon":"src/images/icon.png","localize":[{"start_url":"/hr/","lang":"hr","name":"Perina Kulić","short_name":"Perina","description":"Profesionalna prevoditeljica i lektorica koja se bavi i content writingom i pisanjem po narudžbi. Vrhunska usluga i prihvatljive cijene."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12cb281d7693eb16bd32967f739edf23"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
