// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-writer-js": () => import("./../../../src/pages/content-writer.js" /* webpackChunkName: "component---src-pages-content-writer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proofreader-js": () => import("./../../../src/pages/proofreader.js" /* webpackChunkName: "component---src-pages-proofreader-js" */),
  "component---src-pages-recomend-234-js": () => import("./../../../src/pages/recomend-234.js" /* webpackChunkName: "component---src-pages-recomend-234-js" */),
  "component---src-pages-recomend-iolar-js": () => import("./../../../src/pages/recomend-iolar.js" /* webpackChunkName: "component---src-pages-recomend-iolar-js" */),
  "component---src-pages-recomend-larq-js": () => import("./../../../src/pages/recomend-larq.js" /* webpackChunkName: "component---src-pages-recomend-larq-js" */),
  "component---src-pages-recomend-mp-enterprise-js": () => import("./../../../src/pages/recomend-mp-enterprise.js" /* webpackChunkName: "component---src-pages-recomend-mp-enterprise-js" */),
  "component---src-pages-recomendation-multilingua-js": () => import("./../../../src/pages/recomendation-multilingua.js" /* webpackChunkName: "component---src-pages-recomendation-multilingua-js" */),
  "component---src-pages-recomendation-uefa-2020-js": () => import("./../../../src/pages/recomendation-uefa-2020.js" /* webpackChunkName: "component---src-pages-recomendation-uefa-2020-js" */),
  "component---src-pages-recomendations-js": () => import("./../../../src/pages/recomendations.js" /* webpackChunkName: "component---src-pages-recomendations-js" */),
  "component---src-pages-services-and-discount-js": () => import("./../../../src/pages/services-and-discount.js" /* webpackChunkName: "component---src-pages-services-and-discount-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-translator-js": () => import("./../../../src/pages/translator.js" /* webpackChunkName: "component---src-pages-translator-js" */),
  "component---src-pages-writer-js": () => import("./../../../src/pages/writer.js" /* webpackChunkName: "component---src-pages-writer-js" */)
}

